import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import BagIcon from "components/BagIcon";
import NcInputNumber from "components/NcInputNumber";
import { PRODUCTS } from "data/data";
import { ClockIcon, SparklesIcon } from "@heroicons/react/24/outline";
import IconDiscount from "components/IconDiscount";
import Prices from "components/Prices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import detail1JPG from "images/products/detail1.jpg";
import detail2JPG from "images/products/detail2.jpg";
import detail3JPG from "images/products/detail3.jpg";
import NotifyAddTocart from "./NotifyAddTocart";
import AccordionInfo from "containers/ProductDetailPage/AccordionInfo";
import { Link } from "react-router-dom";
import { ProductsMounts } from "containers/PageCollection";
import {
  Avatar,
  Checkbox,
  Col,
  Empty,
  Form,
  FormInstance,
  Image,
  Radio,
} from "antd";
import { currencyFormatter } from "utils/currencyFormatter";
import {
  cartSlice,
  ComplementGroup,
  ProductToCartAdd,
  ProductToOrder,
} from "store/cart/reducer";
import HIW1img from "images/placeholder-small.png";
import { RootState, useAppDispatch } from "store";
import { ProductReturnStock } from "utils/productReturnStock";
import Carousel from "react-multi-carousel";
import { useSelector } from "react-redux";

export interface ProductQuickViewProps {
  className?: string;
  product?: ProductsMounts;
  close?: () => void;
}
const RadioComponent = ({ complement }: { complement: ComplementGroup }) => {
  return (
    <Form.Item
      name={complement._id}
      id={complement._id}
      rules={[
        {
          required: complement.type === "required",
          message: "Selecione uma opção",
        },
      ]}
    >
      <Radio.Group className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4 ">
        {complement.itens.map((item) => (
          <Radio
            value={item._id}
            className="bg-white shadow-md rounded-lg p-4 w-full dark:bg-slate-800/70"
          >
            <div key={item._id}>
              <div className="d-flex">
                {item.photo && <Avatar size={60} src={item.photo || HIW1img} />}
                <div className="d-flex flex-column ms-3">
                  <p>{item.name}</p>
                </div>
              </div>
              <div className="d-flex justify-content-center mx-4 mt-3">
                <p>
                  {item.price === 0
                    ? "Grátis"
                    : currencyFormatter(item.price || 0)}
                </p>
              </div>
            </div>
          </Radio>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};

const CheckBoxComponent = ({ complement }: { complement: ComplementGroup }) => {
  return (
    <Form.Item
      name={complement._id}
      id={complement._id}
      rules={[
        {
          validator(rule, value, callback) {
            if (!value && complement.type !== "required")
              return Promise.resolve();

            if (!value && complement.type === "required")
              return Promise.reject("Selecione pelo menos uma opção");

            if (complement.type === "required" && value.length === 0) {
              return Promise.reject("Selecione pelo menos uma opção");
            }
            if (
              complement.min_select > value.length &&
              complement.type === "required"
            ) {
              return Promise.reject(
                "Voce deve selecionar no minimo " + complement.min_select
              );
            }
            if (complement.max_select < value.length) {
              return Promise.reject(
                "Voce deve selecionar no máximo " + complement.max_select
              );
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <Checkbox.Group className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4 dark:bg-inherit">
        {complement.itens.map((item) => (
          <Checkbox
            value={item._id}
            style={{ marginLeft: 0 }}
            className="bg-white shadow-md rounded-lg p-4 w-full dark:bg-slate-800/70"
          >
            <div
              key={item._id}
              className="d-flex justify-content-between mb-2 align-items-center "
            >
              <div className="d-flex me-2">
                {item.photo && <Avatar size={60} src={item.photo || HIW1img} />}
                <div className="d-flex flex-column ms-3">
                  <p>{item.name}</p>
                </div>
              </div>
              <div className="d-flex justify-content-center mt-3">
                <p>
                  {item.price === 0
                    ? "Grátis"
                    : currencyFormatter(item.price || 0)}
                </p>
              </div>
            </div>
          </Checkbox>
        ))}
      </Checkbox.Group>
    </Form.Item>
  );
};

const ProductComplements = ({
  product,
  add,
  form,
  onFinishFailed,
}: {
  product: ProductToCartAdd | undefined;
  add: (data: any) => void;
  onFinishFailed?: (error: any) => void;
  form: FormInstance<any>;
}) => {
  const totalValueOld = product?.for_price || product?.price || 0;
  const [valueTotal, setValueTotal] = useState<number>(
    product?.for_price || product?.price || 0
  );
  const [totalContabil, setTotalContabil] = useState<number>(
    product?.for_price || product?.price || 0
  );

  const [valueDiscountPercent, setValueDiscountPercent] = useState<number>(0);
  const [valueDiscountMoney, setValueDiscountMoney] = useState<number>(0);

  const closeModal = () => {
    setValueTotal(0);
    setTotalContabil(0);
    setValueDiscountPercent(0);
    setValueDiscountMoney(0);
    form.resetFields();
  };

  useEffect(() => {
    setValueTotal(product?.for_price || product?.price || 0);
    setTotalContabil(product?.for_price || product?.price || 0);
  }, [product?.for_price, product?.price]);

  const handleChangeDiscountMoney = (value: number, newTotal = 0) => {
    const totalCalc = newTotal || valueTotal;
    setValueDiscountMoney(value);
    const newValueDiscountPercent = +((value / totalCalc) * 100).toFixed(2);
    setTotalContabil(valueTotal - value);
    setValueDiscountPercent(newValueDiscountPercent);
  };

  const makeTotalContabil = ({
    bigValueReplace,
    bigValueAddition,
    Additional,
  }: any) => {
    let totalForCalc = totalValueOld;
    if (Additional) {
      totalForCalc += Additional;
    }

    if (bigValueAddition) {
      totalForCalc += bigValueAddition;
    }

    if (bigValueReplace > totalValueOld) {
      totalForCalc = bigValueReplace;
    }
    setValueTotal(totalForCalc);
    setTotalContabil(totalForCalc);
    handleChangeDiscountMoney(valueDiscountMoney, totalForCalc);
  };

  const makeProductWithComplements = (values) => {
    const getComplementsValuesSelecteds = Object.keys(values).map((key) => {
      return {
        _id: key,
        itens: values[key],
      };
    });

    let complements = product?.complements?.map((complement) => {
      const itensExists = getComplementsValuesSelecteds.find(
        (item) => item._id === complement._id
      )?.itens;

      if (!itensExists) return { ...complement, itens: [] };

      const itens = complement.itens.filter((item) =>
        itensExists.includes(item._id)
      );

      return {
        ...complement,
        itens,
      };
    });

    complements =
      complements?.filter((complement) => complement?.itens?.length) || [];

    let valueSelected = 0;
    let Additional = 0;
    let bigValueAddition = 0;

    complements
      .filter((c) => c.type_summarization === "addition")
      .forEach((complement) => {
        Additional = complement.itens
          .map((item) => item.price || 0)
          .reduce((acc, value) => acc + value, 0);
      });

    complements
      .filter((c) => c.type_summarization === "big_value_addition")
      .forEach((complement) => {
        const bigValue = complement.itens
          .map((item) => item.price || 0)
          .sort((a, b) => b - a)[0];
        bigValueAddition = bigValue;
      });

    complements
      .filter((c) => c.type_summarization === "big_value_replace")
      .forEach((complement) => {
        const bigValueReplace = complement.itens
          .map((item) => item.price || 0)
          .sort((a, b) => b - a)[0];
        valueSelected =
          bigValueReplace > totalValueOld ? bigValueReplace : totalValueOld;
        if (bigValueReplace === 0) {
          valueSelected = 0;
        }
      });

    makeTotalContabil({
      Additional,
      bigValueAddition,
      bigValueReplace: valueSelected,
    });

    const productWithComplements = {
      ...product,
      quantity: 1,
      total_value: valueTotal,
      total_value_unit: valueTotal,
      finish_value_unit: totalContabil,
      finish_value: totalContabil,
      discountPercent_unit: valueDiscountPercent,
      discountPercent: valueDiscountPercent,
      discountMoney_unit: valueDiscountMoney,
      discountMoney: valueDiscountMoney,
      complements,
    };
    return productWithComplements;
  };

  const onFinish = async (values) => {
    const productWithComplements = makeProductWithComplements(values);
    console.log(productWithComplements);
    add(productWithComplements);
    closeModal();
  };

  return (
    <Form
      scrollToFirstError
      onFinish={onFinish}
      onFinishFailed={(error) => {
        if (onFinishFailed) {
          onFinishFailed(error);
        }
      }}
      form={form}
      onFieldsChange={(_, all) => {
        const makeValues = all.reduce((acc, field) => {
          acc[field.name[0]] = field.value;
          return acc;
        }, {});
        makeProductWithComplements(makeValues);
      }}
    >
      <Col>
        {product?.complements?.length === 0 ? (
          <></>
        ) : (
          product?.complements?.map((complement) => {
            const radio =
              complement.min_select === 1 && complement.max_select === 1;
            const checkbox =
              complement.min_select > 1 || complement.max_select > 1;

            if (complement.itens.length === 0) return null;

            return (
              <>
                <AccordionInfo
                  data={[
                    {
                      name: (
                        <div>
                          <div className="d-flex flex-row ">
                            <h5 className="me-3">
                              {complement.name}{" "}
                              {complement.type === "required" && (
                                <span className="text-red-600 ml-1">
                                  Obrigatório
                                </span>
                              )}{" "}
                            </h5>
                            {/* <Tag>
                        {renderTypeGroup(complement.type || "optional")}
                        </Tag>
                      <Tag>
                        {renderTypeSummarization(
                          complement.type_summarization || "addition"
                          )}
                          </Tag> */}
                          </div>
                          <p> {complement.itens.length || 0} opções</p>
                        </div>
                      ),
                      content: (
                        <div className="mx-4">
                          {radio && <RadioComponent complement={complement} />}
                          {checkbox && (
                            <CheckBoxComponent complement={complement} />
                          )}
                        </div>
                      ),
                    },
                  ]}
                />
              </>
            );
          })
        )}
      </Col>
    </Form>
  );
};

const ProductQuickView: FC<ProductQuickViewProps> = ({
  className = "",
  product,
  close,
}) => {
  const { sizes, variants, allOfSizes } = PRODUCTS[0];
  const [variantActive, setVariantActive] = React.useState(0);
  const [sizeSelected, setSizeSelected] = React.useState(sizes ? sizes[0] : "");
  const [qualitySelected, setQualitySelected] = React.useState(1);
  const [status, setStatus] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [form] = Form.useForm();

  const { catalogo } = useSelector((state: RootState) => state.catalogo.data);

  const dispatch = useAppDispatch();

  const onFinishFailed = (error) => {
    try {
      if (error.errorFields.length > 0) {
        setErrorMessage(error.errorFields[0].errors[0]);
        toast.error(error.errorFields[0].errors[0]);
        window!
          .document!.getElementById(error.errorFields[0].name[0])!
          .scrollIntoView();
      }
    } catch (error) {}
  };

  useEffect(() => {
    const reducerTagProduct = product?.props?.reduce((acc, item) => {
      if (item.tag_product) {
        acc = item.tag_product || "";
      }
      return acc;
    }, "");
    setStatus(reducerTagProduct as string);
  }, [product?.props]);

  const handleToAddCart = (values: ProductToOrder) => {
    setErrorMessage("");
    values.quantity = qualitySelected;

    if (values.check_stock === "yes" && values.quantity > values.stock) {
      return toast.error(`Não temos estoque suficiente para ${values.name}`);
    }

    dispatch(cartSlice.actions.addProductToOrder({ product: values }));

    toast(
      <NotifyAddTocart
        productImage={values?.photo}
        qualitySelected={qualitySelected}
        show={true}
        sizeSelected={sizeSelected}
        variantActive={variantActive}
        product={values as any}
      />,
      {
        className: "toastCart",
        position: "top-right",
        hideProgressBar: false,
        pauseOnHover: true,
        progress: undefined,
        draggable: true,
        closeOnClick: true,
      }
    );
    if (close) {
      close();
    }
  };

  const renderStatus = ({ notAbsolut = false }: { notAbsolut?: boolean }) => {
    if (!status || status === "") {
      return null;
    }
    const CLASSES = `${
      notAbsolut ? "" : "absolute top-3 left-3"
    }  px-2.5 py-1.5 text-xs bg-white dark:bg-slate-900 nc-shadow-lg rounded-full flex items-center justify-center text-slate-700 text-slate-900 dark:text-slate-300`;
    if (status === "new") {
      return (
        <div className={CLASSES}>
          <SparklesIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">Novo!</span>
        </div>
      );
    }
    if (status === "discount") {
      return (
        <div className={CLASSES}>
          <IconDiscount className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">Desconto!</span>
        </div>
      );
    }

    if (status === "limited_time") {
      return (
        <div className={CLASSES}>
          <ClockIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">Tempo Limitado!</span>
        </div>
      );
    }
    return null;
  };

  const renderSectionContent = () => {
    return (
      <div className="space-y-8">
        {/* ---------- 1 HEADING ----------  */}
        <div>
          <h2 className="text-2xl font-semibold hover:text-primary-6000 transition-colors">
            <Link to="#">{product?.name}</Link>
          </h2>

          <div className="flex items-center mt-5 space-x-4 sm:space-x-5">
            {/* <div className="flex text-xl font-semibold">$112.00</div> */}
            <Prices
              contentClass="py-1 px-2 md:py-1.5 md:px-3 text-lg font-semibold"
              price={product?.price}
              pricePromo={product?.for_price}
            />

            <div className="h-6 border-l border-slate-300 dark:border-slate-700"></div>

            <div className="flex items-center">
              {/* <StarIcon className="w-5 h-5 pb-[1px] text-yellow-400" />
              <div className="ml-1.5 flex">
                <span>4.9</span>
                <span className="block mx-2">·</span>
                <span className="text-slate-600 dark:text-slate-400 underline">
                  142 reviews
                </span>
              </div> */}
              {/* <span className="hidden sm:block mx-2.5">·</span> */}

              {status && (
                <div className="hidden sm:flex items-center text-sm">
                  <SparklesIcon className="w-3.5 h-3.5" />
                  <span className="ml-1 leading-none">
                    {renderStatus({ notAbsolut: true })}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* ---------- 3 VARIANTS AND SIZE LIST ----------  */}
        {/* <div className="">{renderVariants()}</div>
        <div className="">{renderSizeList()}</div> */}

        {/* View Erro messa */}
        {errorMessage && (
          <div className="text-red-500 text-sm mt-2">{errorMessage}</div>
        )}

        {/*  ---------- 4  QTY AND ADD TO CART BUTTON */}
        {catalogo.type_view !== "catalog_view" && (
          <div className="flex space-x-3.5">
            <div className="flex items-center justify-center bg-slate-100/70 dark:bg-slate-800/70 px-2 py-3 sm:p-3.5 rounded-full">
              <NcInputNumber
                max={ProductReturnStock(product)}
                defaultValue={qualitySelected}
                onChange={setQualitySelected}
              />
            </div>
            <ButtonPrimary
              className="flex-1 flex-shrink-0"
              onClick={() => form.submit()}
            >
              <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
              <span className="ml-3">Adicionar</span>
            </ButtonPrimary>
          </div>
        )}
        {/*  */}
        <hr className=" border-slate-200 dark:border-slate-700"></hr>
        {/*  */}

        {/* ---------- 5 ----------  */}
        <AccordionInfo
          data={[
            {
              name: "Descrição",
              content: product?.description || "",
            },
          ]}
        />
      </div>
    );
  };

  return (
    <div className={`nc-ProductQuickView ${className}`}>
      {/* MAIn */}
      <div className="lg:flex">
        {/* CONTENT */}
        <div className="w-full lg:w-[50%] ">
          {/* HEADING */}
          <div className="relative">
            <div className="hidden lg:block w-full ">
              <Image
                src={product?.photo}
                rootClassName="w-full "
                className="w-full rounded-xl object-contain max-h-96"
                alt="product detail 1"
              />
            </div>
            <div className="lg:hidden">
              <Image.PreviewGroup>
                <Carousel
                  draggable={true}
                  responsive={{
                    superLargeDesktop: {
                      breakpoint: { max: 4000, min: 3000 },
                      items: 5,
                    },
                    desktop: {
                      breakpoint: { max: 3000, min: 1024 },
                      items: 3,
                    },
                    tablet: {
                      breakpoint: { max: 1024, min: 464 },
                      items: 2,
                      paritialVisibilityGutter: 30,
                      partialVisibilityGutter: 30,
                    },
                    mobile: {
                      breakpoint: { max: 464, min: 0 },
                      items: 1,
                      paritialVisibilityGutter: 30,
                      partialVisibilityGutter: 30,
                    },
                  }}
                >
                  {product?.photos.length === 0 && (
                    <div className="flex justify-center m-2">
                      <Image
                        src={product?.photo}
                        className="w-full rounded-xl h-72 object-contain"
                        alt="product detail 1"
                      />
                    </div>
                  )}
                  {product?.photos.map((item, index) => {
                    return (
                      <div key={index} className="flex justify-center m-2">
                        <Image
                          src={item}
                          className="w-full rounded-xl h-72 object-contain"
                          alt="product detail 1"
                        />
                      </div>
                    );
                  })}
                </Carousel>
              </Image.PreviewGroup>
            </div>

            {/* STATUS */}
            {renderStatus({ notAbsolut: false })}
            {/* META FAVORITES */}
            {/* <LikeButton className="absolute right-3 top-3 " /> */}
          </div>
          <div className="hidden lg:grid grid-cols-2 gap-3 mt-3 sm:gap-6 sm:mt-6 xl:gap-5 xl:mt-5">
            <Image.PreviewGroup>
              {product?.photos.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`${
                      index === 0 && "hidden"
                    } rounded-xl h-[200px] sm:h-[250px] xl:h-[300px] w-full`}
                  >
                    <Image
                      src={item}
                      previewPrefixCls="product-quick-view rounded-xl"
                      rootClassName="w-full "
                      className="w-full rounded-xl object-contain  max-h-64 "
                      alt="product detail 1"
                    />
                  </div>
                );
              })}
            </Image.PreviewGroup>
          </div>
        </div>

        {/* SIDEBAR */}
        <div className="w-full lg:w-[50%] pt-6 lg:pt-0 lg:pl-7 xl:pl-8">
          {renderSectionContent()}

          <ProductComplements
            onFinishFailed={onFinishFailed}
            product={product as any}
            add={handleToAddCart}
            form={form}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductQuickView;
